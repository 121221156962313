// import React from 'react'

// export default function adminProject() {
//   return (
//     <div>
//       adminProject
//     </div>
//   )
// }
import React from 'react';
import "../UserDashboard/mainboard.css";
import MyImage from"../Assets/maintenanceImg.png"

export default function adminProject() {
  return (
    <div className='maintainancePage'>
       <div className="maintenanceUnder">
       <div className="maintenanceContent">
     <h1>Something cool is coming up</h1>
      </div>
       <img src={MyImage} alt="This is undermaintenance page" />
       <br/>
      
       </div>
    </div>
  )
}
