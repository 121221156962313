// import React, { useState, useEffect } from "react";
// import { Link, Outlet, useNavigate } from "react-router-dom";
// import "./mainboard.css";

// const UserBoard = () => {
//   const runningProject = (Math.floor(Math.random() * (30 - 10 + 1)) + 10);
//   const PhoneCall = (Math.floor(Math.random() * (300 - 100 + 1)) + 100);
//   // const leadsGen = (Math.floor(Math.random() * (300 - 100 + 1)) + 100);
//   const [menuClass, setMenuClass] = useState("");
//   const [darkTheme, setDarkTheme] = useState(false);
//   const [userName, setUserName] = useState(""); // State to store the dynamic name
//   const navigate = useNavigate();

//   useEffect(() => {
//     // Fetch the user's name from the API
//     const fetchUserName = async () => {
//       const token = localStorage.getItem("access_token"); // Get the token from local storage
//       if (!token) return; // If no token, exit

//       try {
//         const response = await fetch("https://devapi.ivoz.ai/users/profile", {
//           method: "GET",
//           headers: {
//             accept: "application/json",
//             Authorization: `Bearer ${token}`, // Include the bearer token
//           },
//         });

//         if (response.ok) {
//           const data = await response.json();
//           setUserName(data.first_name); // Set the user's name in state
//         } else {
//           console.error("Failed to fetch user data");
//         }
//       } catch (error) {
//         console.error("Error fetching user data:", error);
//       }
//     };

//     fetchUserName();
//   }, []);

//   const handleMenuBtnClick = () => {
//     setMenuClass("show-menu");
//   };

//   const handleCloseBtnClick = () => {
//     setMenuClass("hide-menu");
//   };

//   const handleThemeToggle = () => {
//     setDarkTheme((prevDarkTheme) => !prevDarkTheme);
//     document.body.classList.toggle("dark-theme-variables");
//   };

//   const handleSignOut = () => {
//     // Remove tokens from local storage
//     localStorage.removeItem("accessToken");
//     localStorage.removeItem("access_token");

//     // Navigate to the home page
//     navigate("/");
//   };

//   return (
//     <div>
//       <div className="Board-container">
//         <aside className={menuClass}>
//           <div className="top">
//             <div className="logo">
//               {/* <img src="./images/logo.png" alt=""> */}
//             </div>
//             <div
//               className="close"
//               id="close-btn"
//               onClick={handleCloseBtnClick}
//             >
//               <span className="material-icons-sharp">close</span>
//             </div>
//           </div>
//           <div className="sidebar">
//             <Link to="/userboard" >
//               <span className="material-icons-sharp">grid_view</span>
//               <h3>Dashboard</h3>
//             </Link>
//             <Link to="/userboard/Quickstart">
//               <span className="material-icons-sharp">insights</span>
//               <h3>Quickstart</h3>
//             </Link>
//             <Link to="/userboard/Projects">
//               <span className="material-icons-sharp">receipt_long</span>
//               <h3>Campaigns</h3>
//               <span className="message-count">4</span>
//             </Link>
//             <Link to="/userboard/Profile">
//               <span className="material-icons-sharp">person_outline</span>
//               <h3>Profile</h3>
//             </Link>
//             <Link to="/userboard/transactionHistory">
//               <span className="material-icons-sharp">receipt_long</span>
//               <h3>Transaction History
//               </h3>
//             </Link>
//             <Link to="/userboard/AccessManager">
//               <span className="material-icons-sharp">report_gmailerrorred</span>
//               <h3>Access Manager</h3>
//             </Link>
//             <Link to="/" onClick={handleSignOut}>
//               <span className="material-icons-sharp">logout</span>
//               <h3>Sign Out</h3>
//             </Link>
//           </div>
//         </aside>

//         {/* MAIN SECTION */}
//         <main>
//           <Outlet />
//         </main>

//         {/* END OF MAIN */}

//         {/* RIGHT SECTION STARTS */}
//         <div className="right">
//           <div className="top">
//             <button id="menu-btn" onClick={handleMenuBtnClick}>
//               <span className="material-icons-sharp">menu</span>
//             </button>
//             <div className="theme-toggler" onClick={handleThemeToggle}>
//               <span
//                 className={`material-icons-sharp ${darkTheme ? "" : "active"}`}
//               >
//                 light_mode
//               </span>
//               <span
//                 className={`material-icons-sharp ${darkTheme ? "active" : ""}`}
//               >
//                 dark_mode
//               </span>
//             </div>
//             <div className="profile">
//               <div className="info">
//                 <p>
//                    Hey, <b>{userName || "Guest"}</b>
//                 </p>
//                 <p className="text-muted">User</p>
//               </div>
//               <div className="profile-photo">
//               </div>
//             </div>
//           </div>

//           {/* <div className="recent-updates">
//             <h2>Notifications</h2>
//             <div className="updates">
//               <div className="update">
//                 <div className="profile-photo">
                  
//                 </div>
//                 <div className="message">
//                   <p>
//                     <b>Gunjan</b> Lorem ipsum dolor sit amet consectetur
//                     adipisicing elit.
//                   </p>
//                   <small className="text-muted">2 minutes ago</small>
//                 </div>
//               </div>
//               <div className="update">
//                 <div className="profile-photo">
                  
//                 </div>
//                 <div className="message">
//                   <p>
//                     <b>Gunjan</b> Lorem ipsum dolor sit amet consectetur
//                     adipisicing elit.
//                   </p>
//                   <small className="text-muted">2 minutes ago</small>
//                 </div>
//               </div>
//               <div className="update">
//                 <div className="profile-photo">
                  
//                 </div>
//                 <div className="message">
//                   <p>
//                     <b>Gunjan</b> Lorem ipsum dolor sit amet consectetur
//                     adipisicing elit.
//                   </p>
//                   <small className="text-muted">2 minutes ago</small>
//                 </div>
//               </div>
//             </div>
//           </div>  */}
//           <div className="analytics">
//             <h2>ANALYTICS</h2>
//             <div className="item online">
//               <div className="icon">
//                 <span className="material-icons-sharp">shopping_cart</span>
//               </div>
//               <div className="right">
//                 <div className="info">
//                   <h3>Running Project</h3>
//                   <small className="text-muted">Last 24 Hours</small>
//                 </div>
//                 <h5 className="success">+39%</h5>
//                 <h3>{runningProject}</h3>
//               </div>
//             </div>
//             <div className="item offline">
//               <div className="icon">
//                 <span className="material-icons-sharp">call</span>
//               </div>
//               <div className="right">
//                 <div className="info">
//                   <h3>Total phone call</h3>
//                   <small className="text-muted">Last 24 Hours</small>
//                 </div>
//                 <h5 className="success">+87%</h5>
//                 <h3>{PhoneCall}</h3>
//               </div>
//             </div>
//             {/* <div className="item customers">
//               <div className="icon">
//                 <span className="material-icons-sharp">person</span>
//               </div>
//               <div className="right">
//                 <div className="info">
//                   <h3>Leads Generated</h3>
//                   <small className="text-muted">Last 24 Hours</small>
//                 </div>
//                 <h5 className="success">+25%</h5>
//                 <h3>{leadsGen}</h3>
//               </div>
//             </div> */}
//           </div>
//         </div>
//         {/* END OF RIGHT SECTION */}
//       </div>
//     </div>
//   );
// };

// export default UserBoard;
 


import React, { useState, useEffect } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import "./mainboard.css";

const UserBoard = () => {
  const [menuClass, setMenuClass] = useState("");
  const [darkTheme, setDarkTheme] = useState(false);
  const [userName, setUserName] = useState(""); // State to store the dynamic name
  const [botCount, setBotCount] = useState(0); // State for total bots
  const [balance, setBalance] = useState(0); // State for balance
  const [botPercentage, setBotPercentage] = useState(0); // State for random bot percentage
  const [balancePercentage, setBalancePercentage] = useState(0); // State for random balance percentage
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the user's profile from the API
    const fetchUserProfile = async () => {
      const token = localStorage.getItem("access_token"); // Get the token from local storage
      if (!token) return; // If no token, exit

      try {
        const response = await fetch("https://devapi.ivoz.ai/users/profile", {
          method: "GET",
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`, // Include the bearer token
          },
        });

        if (response.ok) {
          const data = await response.json();
          setUserName(data.first_name); // Set the user's name
          setBotCount(data.bots.length); // Set total bots
          setBalance(data.balance); // Set balance

          generateRandomPercentages(); // Generate random percentages
        } else {
          console.error("Failed to fetch user profile");
        }
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };

    fetchUserProfile();
  }, []);

  const generateRandomPercentages = () => {
    // Generate random percentages for bot and balance
    const randomBotPercentage = Math.floor(Math.random() * 101); // Random between 0-100
    const randomBalancePercentage = Math.floor(Math.random() * 101); // Random between 0-100

    setBotPercentage(randomBotPercentage); // Set random bot percentage
    setBalancePercentage(randomBalancePercentage); // Set random balance percentage
  };

  const handleMenuBtnClick = () => {
    setMenuClass("show-menu");
  };

  const handleCloseBtnClick = () => {
    setMenuClass("hide-menu");
  };

  const handleThemeToggle = () => {
    setDarkTheme((prevDarkTheme) => !prevDarkTheme);
    document.body.classList.toggle("dark-theme-variables");
  };

  const handleSignOut = () => {
    // Remove tokens from local storage
    localStorage.removeItem("accessToken");
    localStorage.removeItem("access_token");

    // Navigate to the home page
    navigate("/");
  };

  return (
    <div>
      <div className="Board-container">
        <aside className={menuClass}>
          <div className="top">
            <div className="logo"></div>
            <div
              className="close"
              id="close-btn"
              onClick={handleCloseBtnClick}
            >
              <span className="material-icons-sharp">close</span>
            </div>
          </div>
          <div className="sidebar">
            <Link to="/userboard">
              <span className="material-icons-sharp">grid_view</span>
              <h3>Dashboard</h3>
            </Link>
            <Link to="/userboard/Quickstart">
              <span className="material-icons-sharp">insights</span>
              <h3>Quickstart</h3>
            </Link>
            <Link to="/userboard/Projects">
              <span className="material-icons-sharp">receipt_long</span>
              <h3>Campaigns</h3>
              <span className="message-count">4</span>
            </Link>
            <Link to="/userboard/Profile">
              <span className="material-icons-sharp">person_outline</span>
              <h3>Profile</h3>
            </Link>
            <Link to="/userboard/transactionHistory">
              <span className="material-icons-sharp">receipt_long</span>
              <h3>Transaction History</h3>
            </Link>
            <Link to="/userboard/AccessManager">
              <span className="material-icons-sharp">report_gmailerrorred</span>
              <h3>Access Manager</h3>
            </Link>
            <Link to="/" onClick={handleSignOut}>
              <span className="material-icons-sharp">logout</span>
              <h3>Sign Out</h3>
            </Link>
          </div>
        </aside>

        {/* MAIN SECTION */}
        <main>
          <Outlet />
        </main>

        {/* RIGHT SECTION */}
        <div className="right">
          <div className="top">
            <button id="menu-btn" onClick={handleMenuBtnClick}>
              <span className="material-icons-sharp">menu</span>
            </button>
            <div className="theme-toggler" onClick={handleThemeToggle}>
              <span
                className={`material-icons-sharp ${darkTheme ? "" : "active"}`}
              >
                light_mode
              </span>
              <span
                className={`material-icons-sharp ${darkTheme ? "active" : ""}`}
              >
                dark_mode
              </span>
            </div>
            <div className="profile">
              <div className="info">
                <p>
                  Hey, <b>{userName || "Guest"}</b>
                </p>
                <p className="text-muted">User</p>
              </div>
            </div>
          </div>

          <div className="analytics">
            <h2>ANALYTICS</h2>
            <div className="item online">
              <div className="icon">
                <span className="material-icons-sharp">shopping_cart</span>
              </div>
              <div className="right">
                <div className="info">
                  <h3>Running Project</h3>
                  <small className="text-muted">+{botPercentage}%</small>
                </div>
                <h3>{botCount}</h3>
              </div>
            </div>
            <div className="item offline">
              <div className="icon">
                <span className="material-icons-sharp">call</span>
              </div>
              <div className="right">
                <div className="info">
                  <h3>Account Balance</h3>
                  <small className="text-muted">+{balancePercentage}%</small>
                </div>
                <h3>${balance}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserBoard;

