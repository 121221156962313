// import React, { useState } from "react";
// import "./editUser.css";
// import "./admin.css"; // Assuming admin.css is available and includes styles for search bar
// import "./adminUserProfiles.css"; // Assuming this file is used for the profile styles

// const EditUser = () => {
//   const [userId, setUserId] = useState("");
//   const [userData, setUserData] = useState(null);
//   const [error, setError] = useState("");
//   const [showPopup, setShowPopup] = useState(false);
//   const [creditAmount, setCreditAmount] = useState("");
//   const [selectedUser, setSelectedUser] = useState(null);

//   // Handle input change
//   const handleInputChange = (e) => {
//     setUserId(e.target.value);
//   };

//   // Fetch user data by ID
//   const fetchUserData = async () => {
//     if (!userId) {
//       setError("Please enter a user ID.");
//       return;
//     }

//     try {
//       const accessToken = localStorage.getItem("access_token");
//       if (!accessToken) {
//         setError("No access token found.");
//         return;
//       }

//       const response = await fetch(
//         `https://devapi.ivoz.ai/admin/users/${userId}`,
//         {
//           method: "POST",
//           headers: {
//             accept: "application/json",
//             Authorization: `Bearer ${accessToken}`,
//           },
//         }
//       );

//       if (response.ok) {
//         const data = await response.json();
//         setUserData(data);
//         setError(""); // Clear any previous errors
//       } else {
//         setError("Failed to fetch user data.");
//       }
//     } catch (error) {
//       setError("Error fetching user data: " + error.message);
//     }
//   };

//   // Handle credit submission
//   const handleCreditSubmit = async () => {
//     if (!creditAmount) {
//       setError("Please enter an amount to credit.");
//       return;
//     }

//     try {
//       const accessToken = localStorage.getItem("access_token");
//       const response = await fetch(
//         "https://devapi.ivoz.ai/admin/modify-balance",
//         {
//           method: "POST",
//           headers: {
//             accept: "application/json",
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${accessToken}`,
//           },
//           body: JSON.stringify({
//             user: selectedUser.email,
//             log: "Credited by admin",
//             amount: parseFloat(creditAmount),
//           }),
//         }
//       );

//       if (response.ok) {
//         const data = await response.json();
//         alert(data.message);
//         setShowPopup(false);
//         setCreditAmount("");
//       } else {
//         setError("Failed to adjust amount");
//       }
//     } catch (error) {
//       setError("Error submitting credit amount: " + error.message);
//     }
//   };

//   return (
//     <div className="admin-container">
//       {/* Search Bar for User ID */}
//       <div className="topBar edituser">
//         <div className="top-bar-search">
//           <input
//             type="text"
//             value={userId}
//             onChange={handleInputChange}
//             placeholder="Enter user's ID"
//             className="searchInput" // Reusing the same class
//           />
//         </div>

//         <div className="top-bar-button">
//           <button className="createUserButton" onClick={fetchUserData}>
//             Search
//           </button>
//         </div>
//       </div>

//       {/* Display User Data */}
//       {userData && (
//         <div className="user-details">
//           <h3>User Details</h3>

//          <div className="edit-users-input">
//          <div className="eu-inputs">
//             <label>Email:</label>
//             <input
//               type="email"
//               value={userData.email}
//               onChange={(e) =>
//                 setUserData({ ...userData, email: e.target.value })
//               }
//             />
//           </div>
//           <div className="eu-inputs">
//             <label>First Name:</label>
//             <input
//               type="text"
//               value={userData.first_name}
//               onChange={(e) =>
//                 setUserData({ ...userData, first_name: e.target.value })
//               }
//             />
//           </div>
//           <div className="eu-inputs">
//             <label>Last Name:</label>
//             <input
//               type="text"
//               value={userData.last_name}
//               onChange={(e) =>
//                 setUserData({ ...userData, last_name: e.target.value })
//               }
//             />
//           </div>
//           <div className="eu-inputs">
//             <label>Phone:</label>
//             <input
//               type="text"
//               value={userData.phone}
//               onChange={(e) =>
//                 setUserData({ ...userData, phone: e.target.value })
//               }
//             />
//           </div>
//           <div className="eu-inputs">
//             <label>Address 1:</label>
//             <input
//               type="text"
//               value={userData.add_1}
//               onChange={(e) =>
//                 setUserData({ ...userData, add_1: e.target.value })
//               }
//             />
//           </div>
//           <div className="eu-inputs">
//             <label>Address 2:</label>
//             <input
//               type="text"
//               value={userData.add_2}
//               onChange={(e) =>
//                 setUserData({ ...userData, add_2: e.target.value })
//               }
//             />
//           </div>
//           <div className="eu-inputs">
//             <label>City:</label>
//             <input
//               type="text"
//               value={userData.city}
//               onChange={(e) =>
//                 setUserData({ ...userData, city: e.target.value })
//               }
//             />
//           </div>
//           <div className="eu-inputs">
//             <label>State:</label>
//             <input
//               type="text"
//               value={userData.state}
//               onChange={(e) =>
//                 setUserData({ ...userData, state: e.target.value })
//               }
//             />
//           </div>
//           <div className="eu-inputs">
//             <label>Zipcode:</label>
//             <input
//               type="text"
//               value={userData.zipcode}
//               onChange={(e) =>
//                 setUserData({ ...userData, zipcode: e.target.value })
//               }
//             />
//           </div>
//          </div>
//         </div>
//       )}

//       {/* Credit Amount Popup */}
//       {showPopup && (
//         <div className="popup">
//           <div className="popup-content">
//             <h3>Credit Amount for {selectedUser?.first_name}</h3>
//             <input
//               type="number"
//               value={creditAmount}
//               onChange={(e) => setCreditAmount(e.target.value)}
//               placeholder="Enter amount"
//             />
//             <br />
//             <button onClick={handleCreditSubmit}>Submit</button>
//             <button onClick={() => setShowPopup(false)}>Cancel</button>
//           </div>
//         </div>
//       )}

//       {/* Error Message */}
//       {error && <p className="error">{error}</p>}
//     </div>
//   );
// };

// export default EditUser;




import React, { useState } from "react";
import "./editUser.css";
import "./admin.css"; // Assuming admin.css is available and includes styles for search bar
import "./adminUserProfiles.css"; // Assuming this file is used for the profile styles

const EditUser = () => {
  const [userId, setUserId] = useState("");
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState("");

  const [isEditable, setIsEditable] = useState(false); // Track if fields are editable

  // Handle input change
  const handleInputChange = (e) => {
    setUserId(e.target.value);
  };

  // Fetch user data by ID
  const fetchUserData = async () => {
    if (!userId) {
      setError("Please enter a user ID.");
      return;
    }

    try {
      const accessToken = localStorage.getItem("access_token");
      if (!accessToken) {
        setError("No access token found.");
        return;
      }

      const response = await fetch(
        `https://devapi.ivoz.ai/admin/users/${userId}`,
        {
          method: "POST",
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setUserData(data);
        setError(""); // Clear any previous errors
      } else {
        setError("Failed to fetch user data.");
      }
    } catch (error) {
      setError("Error fetching user data: " + error.message);
    }
  };



  // Handle user data save
  const handleSave = async () => {
    if (!userData) {
      setError("No user data to save.");
      return;
    }

    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await fetch(
        `https://devapi.ivoz.ai/admin/edit-user/${userId}`,
        {
          method: "POST",
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            first_name: userData.first_name,
            last_name: userData.last_name,
            phone: userData.phone,
            add_1: userData.add_1,
            add_2: userData.add_2,
            city: userData.city,
            state: userData.state,
            zipcode: userData.zipcode,
            password: userData.password,
            is_active: true,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        alert(data.message);
        setIsEditable(false); // Disable editing after saving
      } else {
        setError("Failed to save user data.");
      }
    } catch (error) {
      setError("Error saving user data: " + error.message);
    }
  };

  return (
    <div className="admin-container">
      {/* Search Bar for User ID */}
      <div className="topBar edituser">
        <div className="top-bar-search">
          <input
            type="text"
            value={userId}
            onChange={handleInputChange}
            placeholder="Enter user's ID"
            className="searchInput" // Reusing the same class
          />
        </div>

        <div className="top-bar-button">
          <button className="createUserButton" onClick={fetchUserData}>
            Search
          </button>
        </div>
      </div>

      {/* Display User Data */}
      {userData && (
        <div className="user-details">
          <h3>User Details</h3>
          <div className="edit-users-input">
            <div className="eu-inputs">
              <label>Email:</label>
              <input
                type="email"
                value={userData.email}
                onChange={(e) =>
                  setUserData({ ...userData, email: e.target.value })
                }
                disabled={!isEditable} // Disable input if not editable
              />
            </div>
            <div className="eu-inputs">
              <label>First Name:</label>
              <input
                type="text"
                value={userData.first_name}
                onChange={(e) =>
                  setUserData({ ...userData, first_name: e.target.value })
                }
                disabled={!isEditable} // Disable input if not editable
              />
            </div>
            <div className="eu-inputs">
              <label>Last Name:</label>
              <input
                type="text"
                value={userData.last_name}
                onChange={(e) =>
                  setUserData({ ...userData, last_name: e.target.value })
                }
                disabled={!isEditable} // Disable input if not editable
              />
            </div>
            <div className="eu-inputs">
              <label>Phone:</label>
              <input
                type="text"
                value={userData.phone}
                onChange={(e) =>
                  setUserData({ ...userData, phone: e.target.value })
                }
                disabled={!isEditable} // Disable input if not editable
              />
            </div>
            <div className="eu-inputs">
              <label>Address 1:</label>
              <input
                type="text"
                value={userData.add_1}
                onChange={(e) =>
                  setUserData({ ...userData, add_1: e.target.value })
                }
                disabled={!isEditable} // Disable input if not editable
              />
            </div>
            <div className="eu-inputs">
              <label>Address 2:</label>
              <input
                type="text"
                value={userData.add_2}
                onChange={(e) =>
                  setUserData({ ...userData, add_2: e.target.value })
                }
                disabled={!isEditable} // Disable input if not editable
              />
            </div>
            <div className="eu-inputs">
              <label>City:</label>
              <input
                type="text"
                value={userData.city}
                onChange={(e) =>
                  setUserData({ ...userData, city: e.target.value })
                }
                disabled={!isEditable} // Disable input if not editable
              />
            </div>
            <div className="eu-inputs">
              <label>State:</label>
              <input
                type="text"
                value={userData.state}
                onChange={(e) =>
                  setUserData({ ...userData, state: e.target.value })
                }
                disabled={!isEditable} // Disable input if not editable
              />
            </div>
            <div className="eu-inputs">
              <label>Zipcode:</label>
              <input
                type="text"
                value={userData.zipcode}
                onChange={(e) =>
                  setUserData({ ...userData, zipcode: e.target.value })
                }
                disabled={!isEditable} // Disable input if not editable
              />
            </div>
            {/* Edit/Save Button */}
            {!isEditable ? (
              <button className="createUserButton" onClick={() => setIsEditable(true)}>Edit</button>
            ) : (
              <button className="createUserButton" onClick={handleSave}>Save</button>
            )}
          </div>
        </div>
      )}

      {/* Error Message */}
      {error && <p className="error">{error}</p>}
    </div>
  );
};

export default EditUser;
