// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import './mainboard.css';

// export default function Projects() {
//   const [bots, setBots] = useState([]); // State to store bots data

//   useEffect(() => {
//     const fetchAccountDetails = async () => {
//       const token = localStorage.getItem("access_token");
//       if (!token) return;

//       try {
//         const response = await fetch("https://devapi.ivoz.ai/users/profile", {
//           method: "GET",
//           headers: {
//             accept: "application/json",
//             Authorization: `Bearer ${token}`,
//           },
//         });

//         if (response.ok) {
//           const data = await response.json();
//           setBots(data.bots);
//         } else {
//           console.error("Failed to fetch user data");
//         }
//       } catch (error) {
//         console.error("Error fetching user data:", error);
//       }
//     };

//     fetchAccountDetails();
//   }, []);

//   return (
//     <div>
//        <div className="history">
//           <h2>Campaigns</h2>
//           <table>
//             <thead>
//               <tr>
//                 <th>Sr No</th>
//                 <th>Flow ID</th>
//                 <th>Flow Name</th>
//                 <th>Flow Phone</th>
//                 <th>Status</th>
//                 <th></th>
//               </tr>
//             </thead>
//             <tbody>
//               {bots.map((bot, index) => (
//                 <tr key={index}>
//                   <td>{index + 1}</td>
//                   <td>{bot.bot_id}</td>
//                   <td>{bot.bot_name}</td>
//                   <td>{bot.bot_phone}</td>
//                   <td className="warning">Pending</td>
//                   <td className="primary">
//                     <Link to={`/userboard/bot-details/${bot.bot_id}`}>Details</Link> {/* Link to the details page */}
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//           <Link to="#">Show All</Link>
//         </div>
//     </div>
//   );
// }



import React, { useState, useEffect } from "react";
import "./mainboard.css";
import "./campaign.css";

export default function Campaigns() {
  const [campaigns, setCampaigns] = useState([]); // State to store campaigns data
  const [newCampaignName, setNewCampaignName] = useState("");
  const [newProjectId, setNewProjectId] = useState(""); // State for Project ID
  const [selectedFile, setSelectedFile] = useState(null); // State for selected file
  const [campaignId, setCampaignId] = useState(""); // State for campaign ID
  const [showCreateCampaign, setShowCreateCampaign] = useState(false); // State to toggle create campaign form

  useEffect(() => {
    // Fetch campaigns data from API
    const fetchCampaigns = async () => {
      try {
        const token = localStorage.getItem("access_token"); // Retrieve token from localStorage
        if (!token) {
          throw new Error("Authorization token not found");
        }

        const response = await fetch(
          "https://devapi.ivoz.ai/campaigns/?page=1&per_page=10",
          {
            method: "GET",
            headers: {
              accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch campaigns");
        }
        const data = await response.json();
        setCampaigns(data || []); // Update state with fetched campaigns
      } catch (error) {
        console.error(error);
      }
    };

    fetchCampaigns();
  }, []);

  // Handle creating a campaign
  const handleCreateCampaign = async () => {
    try {
      const token = localStorage.getItem("access_token"); // Retrieve token from localStorage
      if (!token) {
        throw new Error("Authorization token not found");
      }

      const response = await fetch("https://devapi.ivoz.ai/campaigns/", {
        method: "POST",
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          bot_id: newProjectId, // Example bot_id, replace with actual data if needed
          name: newCampaignName,
          project_id: newProjectId, // Pass the project ID from the state
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to create campaign");
      }

      const data = await response.json();
      if (data.status) {
        alert("Campaign created successfully!");
        // Optionally, fetch campaigns again to update the list
        setCampaigns([
          ...campaigns,
          {
            id: data.campaign_id,
            name: newCampaignName,
            bot_id: 2,
            status: "started",
            created_on: new Date(),
          },
        ]);
      } else {
        alert("Error creating campaign");
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Handle file upload
  const handleUploadFile = async () => {
    if (!selectedFile || !campaignId) {
      alert("Please select a file and provide a campaign ID.");
      return;
    }

    const token = localStorage.getItem("access_token");
    if (!token) {
      throw new Error("Authorization token not found");
    }

    const formData = new FormData();
    formData.append("csv_file", selectedFile); // Append the selected file to form data

    try {
      const response = await fetch(
        `https://devapi.ivoz.ai/campaigns/upload-file/${campaignId}`,
        {
          method: "POST",
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to upload file");
      }

      const data = await response.json();
      if (data.status) {
        alert("File Uploaded Successfully");
      } else {
        alert("Error uploading file");
      }
    } catch (error) {
      console.error(error);
      alert("Error uploading file");
    }
  };

  // Toggle campaign status between "Running" and "Stopped"
  const handleToggleStatus = async (id, currentStatus) => {
    try {
      const newStatus = currentStatus === "started" ? "stopped" : "started";

      // Make the POST request to update the status
      const token = localStorage.getItem("access_token"); // Retrieve token from localStorage
      if (!token) {
        throw new Error("Authorization token not found");
      }

      const response = await fetch(
        `https://devapi.ivoz.ai/campaigns/update-status/${id}?status=${newStatus}`,
        {
          method: "POST",
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update campaign status");
      }

      // If the status is updated successfully, update the state
      const updatedCampaigns = campaigns.map((campaign) => {
        if (campaign.id === id) {
          return { ...campaign, status: newStatus };
        }
        return campaign;
      });

      setCampaigns(updatedCampaigns); // Update state with the new status
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="campaign-page">
      {/* Button to toggle create campaign form visibility */}
      <button className="create-campaign-btn campaign-btn" onClick={() => setShowCreateCampaign(!showCreateCampaign)}>
        Create Campaign
      </button>

      {/* Conditional rendering for the create campaign form */}
      {showCreateCampaign && (
        <div className="campaign-setup">
          {/* Step 1: Create Campaign */}
          <div className="create-campaign-step">
            <h2>Create Campaign: Step 1</h2>
            <input
              type="text"
              placeholder="Campaign Name"
              value={newCampaignName}
              onChange={(e) => setNewCampaignName(e.target.value)}
            />
            <input
              type="text"
              placeholder="Project ID" // Text input for Project ID
              value={newProjectId}
              onChange={(e) => setNewProjectId(e.target.value)}
            />
            <button className="campaign-btn" onClick={handleCreateCampaign}>
              Create
            </button>
          </div>

          {/* Step 2: Upload CSV */}
          <div className="upload-campaign-step">
            <h2>Create Campaign: Step 2</h2>
            <input
              type="file"
              accept=".csv"
              onChange={(e) => setSelectedFile(e.target.files[0])} // Handle file selection
            />

            {/* Campaign Dropdown */}
            <select
              value={campaignId}
              onChange={(e) => setCampaignId(e.target.value)} // Update campaign ID on change
            >
              <option value="">Select Campaign</option>
              {campaigns.map((campaign) => (
                <option key={campaign.id} value={campaign.id}>
                  {campaign.name}
                </option>
              ))}
            </select>

            <button className="campaign-btn" onClick={handleUploadFile}>
              Upload File
            </button>
          </div>
        </div>
      )}

      {/* Campaign Table */}
      <div className="campaign-table">
        <h2>Campaigns</h2>
        <table>
          <thead>
            <tr>
              <th>Campaign ID</th>
              <th>Campaign Name</th>
              <th>Project ID</th>
              <th>Created On</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {campaigns.map((campaign, index) => (
              <tr key={index}>
                <td>{campaign.id}</td>
                <td>{campaign.name}</td>
                <td>{campaign.bot_id}</td>
                <td>{new Date(campaign.created_on).toLocaleString()}</td>
                <td
                  className={
                    campaign.status === "started"
                      ? "success"
                      : campaign.status === "stopped"
                      ? "danger"
                      : ""
                  }
                >
                  {campaign.status}
                </td>
                <td>
                  <button
                    className={
                      campaign.status === "started"
                        ? "stop-button"
                        : "start-button"
                    }
                    onClick={() =>
                      handleToggleStatus(campaign.id, campaign.status)
                    }
                  >
                    {campaign.status === "started" ? "STOP" : "START"}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

