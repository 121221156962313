import React, { useState, useEffect } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import "../UserDashboard/mainboard.css";

const AdminBoard = () => {
  const [menuClass, setMenuClass] = useState("");
  const [darkTheme, setDarkTheme] = useState(false);
  const [userName, setUserName] = useState(""); // State to store the dynamic name
  const navigate = useNavigate();
  const ProjectNumber = Math.floor(Math.random() * (2000 - 500 + 1)) + 500;
  const AccountBalance = Math.floor(Math.random() * (2000 - 500 + 1)) + 500;
  const NewProject = Math.floor(Math.random() * (2000 - 500 + 1)) + 500;
  const ProjectNumberP = Math.floor(Math.random() * (60 - 10 + 1)) + 10;
  const AccountBalanceP = Math.floor(Math.random() * (60 - 10 + 1)) + 10;
  const NewProjectP = Math.floor(Math.random() * (60 - 10 + 1)) + 10;

  useEffect(() => {
    // Fetch the user's name from the API
    const fetchUserName = async () => {
      const token = localStorage.getItem("access_token"); // Get the token from local storage
      if (!token) return; // If no token, exit

      try {
        const response = await fetch("https://devapi.ivoz.ai/users/profile", {
          method: "GET",
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`, // Include the bearer token
          },
        });

        if (response.ok) {
          const data = await response.json();
          setUserName(data.name); // Set the user's name in state
        } else {
          console.error("Failed to fetch user data");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserName();
  }, []);

  const handleMenuBtnClick = () => {
    setMenuClass("show-menu");
  };

  const handleCloseBtnClick = () => {
    setMenuClass("hide-menu");
  };

  const handleThemeToggle = () => {
    setDarkTheme((prevDarkTheme) => !prevDarkTheme);
    document.body.classList.toggle("dark-theme-variables");
  };

  const handleSignOut = () => {
    // Remove tokens from local storage
    localStorage.removeItem("accessToken");
    localStorage.removeItem("access_token");

    // Navigate to the home page
    navigate("/");
  };

  return (
    <div>
      <div className="Board-container">
        <aside className={menuClass}>
          <div className="top">
            <div className="logo">
              {/* <img src="./images/logo.png" alt=""> */}
            </div>
            <div
              className="close"
              id="close-btn"
              onClick={handleCloseBtnClick}
            >
              <span className="material-icons-sharp">close</span>
            </div>
          </div>
          <div className="sidebar">
            <Link to="/adminBoard" >
              <span className="material-icons-sharp">grid_view</span>
              <h3>Dashboard</h3>
            </Link>
            <Link to="/adminBoard/Quickstart">
              <span className="material-icons-sharp">insights</span>
              <h3>Quickstart</h3>
            </Link>
            <Link to="/adminBoard/Projects">
              <span className="material-icons-sharp">receipt_long</span>
              <h3>Projects</h3>
              <span className="message-count">4</span>
            </Link>
            <Link to="/adminBoard/Profile">
              <span className="material-icons-sharp">person_outline</span>
              <h3>User Profiles</h3>
            </Link>
            {/* <Link to="/adminBoard/Payment">
              <span className="material-icons-sharp">receipt_long</span>
              <h3>My Profile</h3>
            </Link> */}
            <Link to="/adminBoard/AccessManager">
              <span className="material-icons-sharp">report_gmailerrorred</span>
              <h3>Access Manager</h3>
            </Link>
            <Link to="/" onClick={handleSignOut}>
              <span className="material-icons-sharp">logout</span>
              <h3>Sign Out</h3>
            </Link>
          </div>
        </aside>

        {/* MAIN SECTION */}
        <main>
          <Outlet />
        </main>

        {/* END OF MAIN */}

         {/* RIGHT SECTION STARTS */}
         <div className="right">
          <div className="top">
            <button id="menu-btn" onClick={handleMenuBtnClick}>
              <span className="material-icons-sharp">menu</span>
            </button>
            <div className="theme-toggler" onClick={handleThemeToggle}>
              <span
                className={`material-icons-sharp ${darkTheme ? "" : "active"}`}
              >
                light_mode
              </span>
              <span
                className={`material-icons-sharp ${darkTheme ? "active" : ""}`}
              >
                dark_mode
              </span>
            </div>
            <div className="profile">
              <div className="info">
                <p>
                   Hey, <b>{userName || "Guest"}</b>
                </p>
                <small className="text-muted">User</small>
              </div>
              <div className="profile-photo">
              </div>
            </div>
          </div>

          {/* <div className="recent-updates">
            <h2>Notifications</h2>
            <div className="updates">
              <div className="update">
                <div className="profile-photo">
                  
                </div>
                <div className="message">
                  <p>
                    <b>Gunjan</b> Lorem ipsum dolor sit amet consectetur
                    adipisicing elit.
                  </p>
                  <small className="text-muted">2 minutes ago</small>
                </div>
              </div>
              <div className="update">
                <div className="profile-photo">
                  
                </div>
                <div className="message">
                  <p>
                    <b>Gunjan</b> Lorem ipsum dolor sit amet consectetur
                    adipisicing elit.
                  </p>
                  <small className="text-muted">2 minutes ago</small>
                </div>
              </div>
              <div className="update">
                <div className="profile-photo">
                  
                </div>
                <div className="message">
                  <p>
                    <b>Gunjan</b> Lorem ipsum dolor sit amet consectetur
                    adipisicing elit.
                  </p>
                  <small className="text-muted">2 minutes ago</small>
                </div>
              </div>
            </div>
          </div>  */}
          <div className="analytics">
            <h2>ANALYTICS</h2>
            <div className="item online">
              <div className="icon">
                <span className="material-icons-sharp">shopping_cart</span>
              </div>
              <div className="right">
                <div className="info">
                  <h3>Running Project</h3>
                  <small className="text-muted">Last 24 Hours</small>
                </div>
                <h5 className="success">+{ProjectNumberP}%</h5>
                <h3>{ProjectNumber}</h3>
              </div>
            </div>
            <div className="item offline">
              <div className="icon">
                <span className="material-icons-sharp">local_mall</span>
              </div>
              <div className="right">
                <div className="info">
                  <h3>Account Balance</h3>
                  <small className="text-muted">Last 24 Hours</small>
                </div>
                <h5 className="danger">-{AccountBalanceP}%</h5>
                <h3>{AccountBalance}</h3>
              </div>
            </div>
            <div className="item customers">
              <div className="icon">
                <span className="material-icons-sharp">person</span>
              </div>
              <div className="right">
                <div className="info">
                  <h3>New Project</h3>
                  <small className="text-muted">Last 24 Hours</small>
                </div>
                <h5 className="success">+{NewProjectP}%</h5>
                <h3>{NewProject}</h3>
              </div>
            </div>
          </div>
        </div>
        {/* END OF RIGHT SECTION */}
      </div>
    </div>
  );
};

export default AdminBoard;
 