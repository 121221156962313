import './App.css';
import SignIn from './Components/Auth/auth';
import { HashRouter as Router, Route, Routes } from "react-router-dom";
// USER DASHBOARD
import UserBoard from './Components/UserDashboard/userBoard';
import Home from './Components/UserDashboard/home';
import Intgration from './Components/UserDashboard/Intgration';
import Profile from './Components/UserDashboard/profile';
import Accessmanager from './Components/UserDashboard/manager';
import Quickstart from './Components/UserDashboard/quickstart';
import Payment from './Components/UserDashboard/transctionHistory';
import Projects from './Components/UserDashboard/projects';
import BotDetails from './Components/UserDashboard/botDetails';
// ADMIN DASHBOARD
import AdminBoard from './Components/AdminDashboard/adminBoard';
import AdminHome from './Components/AdminDashboard/adminHome';
import AdminProject from './Components/AdminDashboard/adminProject';
import AdminProfile from './Components/AdminDashboard/usersProfile';
import AdminQuickstart from './Components/AdminDashboard/adminQuickstart';
import AdminPayment from './Components/AdminDashboard/myProfile';
import AdminManager from './Components/AdminDashboard/adminManager';
import AdminIntegration from './Components/AdminDashboard/adminIntegration';
import CreateUser from './Components/AdminDashboard/createUser';
import EditUser from './Components/AdminDashboard/editUser';
// AUTH--
import ForgotPassword from './Components/Auth/forgot-password';


function App() {
  return (
    <>
      <Router>
        <Routes>  
          <Route exact path='/' element={<SignIn />} />
          <Route exact path="/forget-password" element={<ForgotPassword />} />
          <Route path='/userboard' element={<UserBoard />}>
            <Route index element={<Home />} />
            <Route path="bot-details/:botId" element={<BotDetails />} />
            <Route path='Integrations' element={<Intgration />} />
            <Route path='Projects' element={<Projects />} />
            <Route path='transactionHistory' element={<Payment />} />
            <Route path='Quickstart' element={<Quickstart />} />
            <Route path='AccessManager' element={<Accessmanager />} />
            <Route path='Profile' element={<Profile />} />
          </Route>

          {/* ADMIN DASHBOARD */}
          <Route path='/adminBoard' element={<AdminBoard />}>
            <Route index element={<AdminHome />} />
            <Route path='Integrations' element={<AdminIntegration />} />
            <Route path='Projects' element={<AdminProject />} />
            <Route path='Payment' element={<AdminPayment />} />
            <Route path='Quickstart' element={<AdminQuickstart />} />
            <Route path='AccessManager' element={<AdminManager />} />
            <Route path='Profile' element={<AdminProfile />} />
            <Route path='createUser' element={<CreateUser />} />
            <Route path='editUser' element={<EditUser />} />
          </Route>


        </Routes>
      </Router>

    </>

  );
}

export default App;

// import React from "react";
// import Projects from './Components/Dashboard/projects';

// function App() {
//   return (
//     <div className="App">
//       <Projects />
//     </div>
//   );
// }

// export default App;