// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import "./auth.css";
// import { FaLock } from "react-icons/fa";

// const ForgotPassword = () => {
  

//   return (
//     <div className="container">
//       <div className="forms-container">
//         <div className="signin-signup">
//           <form
//             action="#"
//           >
//             <h2 className="title">Forgot Password</h2>
//             <div className="input-field">
//               <i>
//                 <FaLock />
//               </i>
//               <input
//                 type="password"
//                 placeholder="New Password"
//                 name="New Password"
//               />
//             </div>
//             <div className="input-field">
//               <i>
//                 <FaLock />
//               </i>
//               <input
//                 type="password"
//                 placeholder="Confirm Password"
//                 name="Confirm Password"
//               />
//             </div>
//             <input type="submit" value="Submit" className="btn solid" />

//           </form>
//         </div>
//       </div>

//       <div className="panels-container">
//         <div className="panel left-panel">
//           <div className="content">
//             <h3>New here?</h3>
//             <p>Join us today and unlock exclusive features!</p>
//             <button className="btn transparent">
//               Sign up
//             </button>
//           </div>
//           <img
//             src="https://i.ibb.co/6HXL6q1/Privacy-policy-rafiki.png"
//             className="image"
//             alt=""
//           />
//         </div>
//         <div className="panel right-panel">
//           <div className="content">
//             <h3>One of us?</h3>
//             <p>
//               Welcome! Sign in to continue or create an account to get started
//               with us.
//             </p>
//             <button className="btn transparent" >
//               Sign in
//             </button>
//           </div>
//           <img
//             src="https://i.ibb.co/nP8H853/Mobile-login-rafiki.png"
//             className="image"
//             alt=""
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ForgotPassword;

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./auth.css";
import { FaLock } from "react-icons/fa";

const ForgotPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [token, setToken] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Extract the token from the URL
    const queryParams = new URLSearchParams(location.search);
    const extractedToken = queryParams.get("token");
    if (extractedToken) {
      setToken(extractedToken);
    } else {
      alert("Invalid token. Please try the password reset process again.");
      navigate("/"); // Redirect to home or login
    }
  }, [location, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      alert("Passwords do not match. Please try again.");
      return;
    }

    try {
      const response = await fetch("https://devapi.ivoz.ai/auth/forget-password", {
        method: "POST",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: token,
          new_password: newPassword,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        alert(data.message || "Password Reset Successfully");
        navigate("/signin"); // Redirect to Sign-In page
      } else {
        alert(data.message || "Password reset failed. Please try again.");
      }
    } catch (error) {
      console.error("Error resetting password:", error);
      alert("An unexpected error occurred. Please try again.");
    }
  };

  return (
    <div className="container">
      <div className="forms-container">
        <div className="signin-signup">
          <form className="forgot-password-form" onSubmit={handleSubmit}>
            <h2 className="title">Reset Password</h2>
            <div className="input-field">
              <i>
                <FaLock />
              </i>
              <input
                type="password"
                placeholder="New Password"
                name="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </div>
            <div className="input-field">
              <i>
                <FaLock />
              </i>
              <input
                type="password"
                placeholder="Confirm Password"
                name="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>
            <input type="submit" value="Submit" className="btn solid" />
          </form>
        </div>
      </div>

      <div className="panels-container">
        <div className="panel left-panel">
          <div className="content">
            <h3>New here?</h3>
            <p>Join us today and unlock exclusive features!</p>
            <button className="btn transparent" onClick={() => navigate("/")}>
              Sign up
            </button>
          </div>
          <img
            src="https://i.ibb.co/6HXL6q1/Privacy-policy-rafiki.png"
            className="image"
            alt=""
          />
        </div>
        <div className="panel right-panel">
          <div className="content">
            <h3>One of us?</h3>
            <p>
              Welcome! Sign in to continue or create an account to get started with us.
            </p>
            <button className="btn transparent" onClick={() => navigate("/signin")}>
              Sign in
            </button>
          </div>
          <img
            src="https://i.ibb.co/nP8H853/Mobile-login-rafiki.png"
            className="image"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
