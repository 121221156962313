// import React, { useState, useEffect } from "react";
// import "./admin.css";
// import "./adminUserProfiles.css";
// import { useNavigate } from "react-router-dom";

// const AdminProfile = () => {
//   const [query, setQuery] = useState("");
//   const [users, setUsers] = useState([]);
//   const [filteredUsers, setFilteredUsers] = useState([]);
//   const [selectedUser, setSelectedUser] = useState(null);
//   const [creditAmount, setCreditAmount] = useState("");
//   const [error, setError] = useState("");
//   const [showPopup, setShowPopup] = useState(false);
//   const navigate = useNavigate();

//   // Fetch all users on component mount
//   useEffect(() => {
//     fetchUsers();
//   }, []);

//   // Fetch users from the API
//   const fetchUsers = async () => {
//     try {
//       const accessToken = localStorage.getItem("access_token");
//       if (!accessToken) {
//         setError("No access token found in local storage");
//         return;
//       }

//       const response = await fetch(
//         "https://devapi.ivoz.ai/admin/users?page=1&per_page=15",
//         {
//           headers: {
//             accept: "application/json",
//             Authorization: `Bearer ${accessToken}`,
//           },
//         }
//       );

//       if (response.ok) {
//         const data = await response.json();
//         setUsers(data);
//         setFilteredUsers(data);
//       } else {
//         setError("Failed to fetch users");
//       }
//     } catch (error) {
//       setError("Error fetching users: " + error.message);
//     }
//   };

//   // Update filtered users based on search query
//   useEffect(() => {
//     if (query) {
//       setFilteredUsers(
//         users.filter((user) =>
//           user.first_name.toLowerCase().includes(query.toLowerCase())
//         )
//       );
//     } else {
//       setFilteredUsers(users);
//     }
//   }, [query, users]);

//   // Handle credit submission
//   const handleCreditSubmit = async () => {
//     if (!creditAmount) {
//       setError("Please enter an amount to credit.");
//       return;
//     }

//     try {
//       const accessToken = localStorage.getItem("access_token");
//       const response = await fetch(
//         "https://devapi.ivoz.ai/admin/modify-balance",
//         {
//           method: "POST",
//           headers: {
//             accept: "application/json",
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${accessToken}`,
//           },
//           body: JSON.stringify({
//             user: selectedUser.email,
//             log: "Credited by admin",
//             amount: parseFloat(creditAmount),
//           }),
//         }
//       );

//       if (response.ok) {
//         const data = await response.json();
//         alert(data.message);
//         setShowPopup(false);
//         setCreditAmount("");
//       } else {
//         setError("Failed to adjust amount");
//       }
//     } catch (error) {
//       setError("Error submitting credit amount: " + error.message);
//     }
//   };

//   return (
//     <div className="admin-container">
//       {/* Search Bar and Create User Button */}
//       <div className="topBar">
//         <div className="top-bar-search">
//           <input
//             type="text"
//             value={query}
//             onChange={(e) => setQuery(e.target.value)}
//             placeholder="Enter Username"
//             className="searchInput"
//           />
//         </div>

//         <div className="top-bar-button">
//           <button
//             className="createUserButton"
//             onClick={() => navigate("/adminBoard/editUser")}
//           >
//             Edit User
//           </button>

//           <button
//             className="createUserButton"
//             onClick={() => navigate("/adminBoard/createUser")}
//           >
//             Create User
//           </button>
//         </div>
//       </div>

//       {/* Table with Users */}
//       <div className="history">
//         <h2>Users</h2>
//         <table>
//           <thead>
//             <tr>
//               <th>Sr No</th>
//               <th>User Name</th>
//               <th>Email</th>
//               <th>Credit Amount</th>
//             </tr>
//           </thead>
//           <tbody>
//             {filteredUsers.map((user, index) => (
//               <tr key={user.id}>
//                 <td>{index + 1}</td>
//                 <td>{user.first_name}</td>
//                 <td>{user.email}</td>
//                 <td>
//                   <button
//                     className="creditButton"
//                     onClick={() => {
//                       setSelectedUser(user);
//                       setShowPopup(true);
//                     }}
//                   >
//                     Credit
//                   </button>
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>

//       {/* Credit Amount Popup */}
//       {showPopup && (
//         <div className="popup">
//           <div className="popup-content">
//             <h3>Credit Amount for {selectedUser?.name}</h3>
//             <input
//               type="number"
//               value={creditAmount}
//               onChange={(e) => setCreditAmount(e.target.value)}
//               placeholder="Enter amount"
//             />
//             <br />
//             <button onClick={handleCreditSubmit}>Submit</button>
//             <button onClick={() => setShowPopup(false)}>Cancel</button>
//           </div>
//         </div>
//       )}

//       {/* Error Message */}
//       {error && <p className="error">{error}</p>}
//     </div>
//   );
// };

// export default AdminProfile;


import React, { useState, useEffect } from "react";
import "./admin.css";
import "./adminUserProfiles.css";
import { useNavigate } from "react-router-dom";

const AdminProfile = () => {
  const [query, setQuery] = useState("");
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [creditAmount, setCreditAmount] = useState("");
  const [error, setError] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();
  

  // Fetch all users on component mount
  useEffect(() => {
    fetchUsers();
  }, []);

  // Fetch users from the API
  const fetchUsers = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      if (!accessToken) {
        setError("No access token found in local storage");
        return;
      }

      const response = await fetch(
        "https://devapi.ivoz.ai/admin/users?page=1&per_page=15",
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setUsers(data);
        setFilteredUsers(data);
      } else {
        setError("Failed to fetch users");
      }
    } catch (error) {
      setError("Error fetching users: " + error.message);
    }
  };

  // Update filtered users based on search query (search by email)
  useEffect(() => {
    if (query) {
      setFilteredUsers(
        users.filter((user) =>
          user.email.toLowerCase().includes(query.toLowerCase()) // Search by email
        )
      );
    } else {
      setFilteredUsers(users);
    }
  }, [query, users]);

  // Handle credit submission
  const handleCreditSubmit = async () => {
    if (!creditAmount) {
      setError("Please enter an amount to credit.");
      return;
    }

    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await fetch(
        "https://devapi.ivoz.ai/admin/modify-balance",
        {
          method: "POST",
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            user: selectedUser.email,
            log: "Credited by admin",
            amount: parseFloat(creditAmount),
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        alert(data.message);
        setShowPopup(false);
        setCreditAmount("");
      } else {
        setError("Failed to adjust amount");
      }
    } catch (error) {
      setError("Error submitting credit amount: " + error.message);
    }
  };

  return (
    <div className="admin-container">
      {/* Search Bar and Create User Button */}
      <div className="topBar">
        <div className="top-bar-search">
          <input
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder="Enter Email"
            className="searchInput"
          />
        </div>

        <div className="top-bar-button">
          <button
            className="createUserButton"
            onClick={() => navigate("/adminBoard/editUser")}
          >
            Edit User
          </button>

          <button
            className="createUserButton"
            onClick={() => navigate("/adminBoard/createUser")}
          >
            Create User
          </button>
        </div>
      </div>

      {/* Table with Users */}
      <div className="history">
        <h2>Users</h2>
        <table>
          <thead>
            <tr>
              <th>Sr No</th>
              <th>User Name</th>
              <th>Email</th>
              <th>Credit Amount</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user, index) => (
              <tr key={user.id}>
                <td>{index + 1}</td>
                <td>{user.first_name}</td>
                <td>{user.email}</td>
                <td>
                  <button
                    className="creditButton"
                    onClick={() => {
                      setSelectedUser(user);
                      setShowPopup(true);
                    }}
                  >
                    Credit
                  </button> 
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Credit Amount Popup */}
      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <h3>Credit Amount for {selectedUser?.name}</h3>
            <input
              type="number"
              value={creditAmount}
              onChange={(e) => setCreditAmount(e.target.value)}
              placeholder="Enter amount"
            />
            <br />
            <button onClick={handleCreditSubmit}>Submit</button>
            <button onClick={() => setShowPopup(false)}>Cancel</button>
          </div>
        </div>
      )}

      {/* Error Message */}
      {error && <p className="error">{error}</p>}
    </div>
  );
};

export default AdminProfile;
